<template>
  <router-link
    :to="{ name: 'admins' }"
    class="block mb-3 text-lg tw-font-semibold tw-text-blue-700 tw-hover:tx-text-blue-900"
  >
    <i class="mr-2 pi pi-arrow-left"></i>
    Kembali
  </router-link>

  <div class="card tw-pb-20">
    <h5 class="tw-text-lg tw-font-semibold">Detail Admin</h5>
    <hr class="mt-2 mb-4">
    <div class="grid mb-2">
      <div class="col-6 md:col-3 opacity-60">
        Email
      </div>
      <div class="col-6 md:col-7 tw-font-medium">
        {{ data.email }}
      </div>
    </div>

    <div class="grid mb-2">
      <div class="col-6 md:col-3 opacity-60">
        No. HP
      </div>
      <div class="col-6 md:col-7 tw-font-medium">
        {{ data.phone_number ? data.phone_number : '-' }}
      </div>
    </div>

    <div class="grid mb-2">
      <div class="col-6 md:col-3 opacity-60">
        Login Terakhir
      </div>
      <div class="col-6 md:col-7 tw-font-medium">
        {{ dateTime(data.last_login_time) }}
      </div>
    </div>

    <div class="grid mb-2">
      <div class="col-6 md:col-3 opacity-60">
        Status
      </div>
      <div class="col-6 md:col-7 tw-font-medium">
        <Tag
          :severity="severity(data.status)"
          :value="admin_status.find((e) => e.id === data.status).value"
          v-if="data.status != null">
        </Tag>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    props: ['id'],
    data() {
      return {
        data: {},
        admin_status: [
          { id: 0, value: 'InActive' },
          { id: 1, value: 'Active' },
          { id: 2, value: 'Suspend' },
        ],
      }
    },
    mounted () {
      this.getData();
    },
    methods: {
      getData() {
        this.axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/auth/user/${this.id}/admin`).then(response => {
          if (response.status === 200) {
            this.data = response.data.data
          }
        })
      },
      severity(status) {
        let result = '';
        switch (status) {
          case 0:
            result = 'warning';
            break;
          case 1:
            result = 'success';
            break;
          default:
            result = 'danger';
            break;
        }
        return result;
      },
      dateTime(value) {
        return moment(value).locale('id_ID').format('dddd, DD MMMM YYYY HH:mm:ss');
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
